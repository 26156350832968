export function validDestination(destination) {
  if (!destination || destination.length === 0) {
    return false;
  }

  if (destination.indexOf('http') === 0) {
    return false;
  }

  return destination.indexOf('//') !== 0;
}

