/* eslint-disable jsx-a11y/label-has-for,css-modules/no-unused-class */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Inputs.css';

class Control extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
  };

  static childContextTypes = {
    controlName: PropTypes.string,
  };

  getChildContext() {
    return {
      controlName: this.props.name,
    };
  }

  render() {
    const {
      children,
      className,
    } = this.props;

    return (
      <div className={cx(s.control, className)}>
        {children}
      </div>);
  }
}

export default withStyles(s)(Control);
