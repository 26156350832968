// This could be implemented using promises,
// but on client side this is not used except here.

export function addEvent(category, action, label, value, cb) {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'generic',
      'event-category': category || null,
      'event-action': action || null,
      'event-label': label || null,
      'event-value': value || null,
      eventCallback: cb,
    });
  } else if (cb) {
    cb();
  }
}

export default addEvent;
