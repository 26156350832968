/* eslint-disable jsx-a11y/label-has-for,css-modules/no-unused-class */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Inputs.css';

class Label extends React.Component {
  static propTypes = {
    required: PropTypes.bool,
    className: PropTypes.string,
    htmlFor: PropTypes.string,
    children: PropTypes.node.isRequired,
    forCheckbox: PropTypes.bool,
  };

  static contextTypes = {
    controlName: PropTypes.string,
  };

  render() {
    const {
      className,
      children,
      required,
      htmlFor,
      forCheckbox,
    } = this.props;
    const { controlName } = this.context;

    return (
      <label
        htmlFor={htmlFor || controlName}
        className={cx(s.label, forCheckbox ? s.labelForCheckbox : null, className)}
      >
        {children}
        {required && <span className={s.required}>*</span>}
      </label>);
  }
}

export default withStyles(s)(Label);
