/* eslint-disable css-modules/no-unused-class */
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { TiInfo } from 'react-icons/ti';
import s from './Message.css';
import Message from './Message';

class WarningMessage extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  render() {
    const { children } = this.props;

    return (
      <Message className={s.warningMessage} icon={TiInfo}>
        {children}
      </Message>);
  }
}

export default withStyles(s)(WarningMessage);

