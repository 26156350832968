/* eslint-disable css-modules/no-unused-class */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Inputs.css';

class TextInput extends React.Component {
  static propTypes = {
    type: PropTypes.string,
    name: PropTypes.string,
    required: PropTypes.bool,
    minLength: PropTypes.number,
    maxLength: PropTypes.number,
    id: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    value: PropTypes.string,
  };

  static contextTypes = {
    controlName: PropTypes.string,
  };

  static defaultProps = {
    type: 'text',
  };

  render() {
    const { className, id, name } = this.props;
    const { controlName } = this.context;

    return (
      <input
        {...this.props}
        className={cx(s.input, className)}
        id={id || controlName}
        name={name || controlName}
      />);
  }
}

export default withStyles(s)(TextInput);
