import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { FaFacebook, FaInstagram, FaGoogle } from 'react-icons/fa';
import s from './Login.css';
import { validDestination } from '../../core/urlUtils';
import { addEvent } from '../../core/analytics';
import TextInput from '../Forms/Inputs/TextInput';
import Control from '../Forms/Inputs/Control';
import Label from '../Forms/Inputs/Label';
import MoreButton from '../Button/MoreButton';
import ErrorMessage from '../Forms/Messages/ErrorMessage';
import WarningMessage from '../Forms/Messages/WarningMessage';

function onClick(e) {
  e.preventDefault();
  const target = e.currentTarget;
  const type = target.dataset.logintype;
  addEvent('User', 'Login', type, null, () => {
    if (target.href) {
      window.location.href = target.href;
    } else {
      target.submit();
    }
  });
}

class Login extends React.Component {
  static propTypes = {
    messages: PropTypes.arrayOf(PropTypes.string),
    destination: PropTypes.string,
    hideEmailPassword: PropTypes.bool,
    hideProfileTypes: PropTypes.arrayOf(PropTypes.string),
    buttonText: PropTypes.string,
    hideOrSeparator: PropTypes.bool,
    className: PropTypes.string,
  };

  static defaultProps = {
    buttonText: 'Log in met',
  };

  render() {
    const {
      messages,
      destination,
      hideEmailPassword,
      hideProfileTypes,
      buttonText,
      hideOrSeparator,
      className,
    } = this.props;

    let destinationSuffix = '';
    if (validDestination(destination)) {
      destinationSuffix = `?destination=${encodeURIComponent(destination)}`;
    }

    return (
      <div className={cx(s.container, className)}>
        {
          messages && messages.length === 1 &&
            <ErrorMessage>{messages[0]}</ErrorMessage>
        }
        {
          messages && messages.length > 1 &&
          <WarningMessage>
            <ul>
              {messages.map(message => (<li key={message}>{message}</li>))}
            </ul>
          </WarningMessage>
        }

        { (!hideProfileTypes || hideProfileTypes.indexOf('facebook') === -1) &&
        <div className={s.formGroup}>
          <a className={s.facebook} href={`/login/facebook${destinationSuffix}`} data-logintype="facebook" onClick={onClick} rel="nofollow">
            <FaFacebook size={20} className={s.icon} />
            <span>{`${buttonText} Facebook`}</span>
          </a>
        </div>
        }
        { (!hideProfileTypes || hideProfileTypes.indexOf('google') === -1) &&
        <div className={s.formGroup}>
          <a className={s.google} href={`/login/google${destinationSuffix}`} data-logintype="google" onClick={onClick} rel="nofollow">
            <FaGoogle size={20} className={s.icon} />
            <span>{`${buttonText} Google`}</span>
          </a>
        </div>
        }
        { (!hideProfileTypes || hideProfileTypes.indexOf('instagram') === -1) &&
        <div className={s.formGroup}>
          <a className={s.instagram} href={`/login/instagram${destinationSuffix}`} data-logintype="instagram" onClick={onClick} rel="nofollow">
            <FaInstagram size={20} className={s.icon} />
            <span>{`${buttonText} Instagram`}</span>
          </a>
        </div>
        }
        {!hideOrSeparator && <strong className={s.lineThrough}>OF</strong>}
        {!hideEmailPassword &&
        <div>
          <form method="post" onSubmit={onClick} action="/login/local" data-logintype="password">
            {destination && <input type="hidden" name="destination" value={destination} />}
            <Control name="email">
              <Label required>E-mailadres:</Label>
              <TextInput required type="email" />
            </Control>
            <Control name="password">
              <Label required>Wachtwoord:</Label>
              <TextInput required type="password" />
            </Control>
            <Control>
              <MoreButton type="submit">Inloggen</MoreButton>
            </Control>
          </form>
          <strong className={s.lineThrough}>OF</strong>
          <MoreButton href="/wachtwoordvergeten">Wachtwoord vergeten</MoreButton>
          <MoreButton href="/aanmelden">Aanmelden</MoreButton>
        </div>
        }
      </div>
    );
  }
}

export default withStyles(s)(Login);
