/* eslint-disable css-modules/no-unused-class */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Message.css';

class Message extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    icon: PropTypes.func,
    className: PropTypes.string,
  };

  render() {
    const { children, icon: Icon, className } = this.props;

    return (
      <div className={cx(s.message, className)}>
        { Icon && <Icon size={30} /> }
        {children}
      </div>);
  }
}

export default withStyles(s)(Message);


